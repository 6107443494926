import React from 'react'
import { Grid } from '@mui/material'
import logo from '../../assets/logos/logo_white.png'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined'
import InstagramIcon from '@mui/icons-material/Instagram'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
    const navigate = useNavigate()

    const onClickLink = (link) => {
        navigate(link)
        window.scrollTo(0, 0)
    }

    return (
        <section id='footer'>
            <Grid container justifyContent='center'>
                <Grid container item lg={10.5} xs={11} justifyContent='space-between'>
                    <Grid container item lg={3.75} md={3.75} xs={12} sx={{ padding: '1rem 0' }}>
                        <Grid item lg={12} md={12} sm={6} xs={12}>
                            <img src={logo} loading='lazy' alt='logo' width='45%' />
                        </Grid>
                        <Grid item xs={12}>
                            <p>Entreprise de rénovation à Saint mars de coutais. Chantiers, constructions, rénovations immobilières autour de Nantes.
                        Rénovation de maisons et appartements pour professionnels et particuliers</p>
                        </Grid>
                    </Grid>
                    <Grid container item lg={3.75} md={3.75} xs={12} sx={{ padding: '1rem 0' }}>
                        <Grid item xs={12}>
                            <h3>Contactez-nous</h3>
                        </Grid>
                        <Grid item xs={12}>
                            <p><a href='https://goo.gl/maps/g9UiBoeSmpUNLMQ1A' target='_blank' rel='noreferrer'>9 rue des artisans 44680 SAINT MARS DE COUTAIS</a></p>
                        </Grid>
                        <Grid item xs={12}>
                            <p><a href='tel:0952035208'>09 52 03 52 08</a></p>
                        </Grid>
                        <Grid item xs={12}>
                            <p>8h30 – 12h30 | 14h00 - 17h30</p>
                        </Grid>
                        <Grid item xs={12}>
                            <p><a href='mailto:mythermy@hotmail.fr'>mythermy@hotmail.fr</a></p>
                        </Grid>
                    </Grid>
                    <Grid container item lg={2.5} md={2.5} xs={12} sx={{ padding: '1rem 0' }}>
                        <Grid item xs={12}>
                            <h3>Suivez-nous</h3>
                        </Grid>
                        <Grid container item lg={12} md={12} xs={7}>
                            <Grid item lg={1} md={2} sm={1} xs={3}>
                                <FacebookOutlinedIcon color='#fafafa' />
                            </Grid>
                            <Grid item lg={11} md={10} sm={11} xs={9}>
                                <a href='https://fr-fr.facebook.com/mythermy44/' target='_blank' rel='noreferrer'>Facebook</a>
                            </Grid>
                        </Grid>
                        <Grid container item lg={12} md={12} xs={7}>
                            <Grid item lg={1} md={2} sm={1} xs={3}>
                                <InstagramIcon color='#fafafa' />
                            </Grid>
                            <Grid item lg={11} md={10} sm={11} xs={9}>
                                <a href='https://www.instagram.com/mythermy44/' target='_blank' rel='noreferrer'>Instagram</a>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sx={{ padding: '2rem 0' }}>
                        <Grid item xs={12}>
                            <hr />
                        </Grid>
                        <Grid container item xs={12} columnSpacing={7} sx={{ paddingTop: '1rem' }}>
                            <Grid item>
                                <span style={{ cursor: 'pointer' }} onClick={() => onClickLink('/cgu')}>Conditions générales d’utilisation</span>
                            </Grid>
                            <Grid item sx={{ textAlign: { md: 'center' }, marginTop: { xs: '0.5rem', md: 0 } }}>
                                <span style={{ cursor: 'pointer' }} onClick={() => onClickLink('/cgv')}>Conditions générales de vente</span>
                            </Grid>
                            <Grid item sx={{ marginTop: { xs: '0.5rem', md: 0 } }}>
                                <span>Copyright © 2022 LTWeb</span>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </section>
    )
}

export default Footer