import React from 'react'
import { Grid } from '@mui/material'

const Cgu = () => {
    return (
        <Grid container className='cgu'>
            <Grid item xs={12}>
                <h2>Conditions générales d'utilisation</h2>

                <p>Le présent site Internet <a href='https://mythermy.fr'>https://mythermy.fr</a> est édité par la société MY THERMY, SARL au capital de 10 000,00 € dont le siège social est situé 9 rue des artisans 44680 Saint Mars de Coutais</p>
                <p><strong>Téléphone</strong> : 09 52 03 52 08</p>
                <p>L'accès au Site, sa consultation et son utilisation sont subordonnés à l'acceptation sans réserve des présentes conditions générales d'utilisation.</p>
                <p>En naviguant sur le Site, vous reconnaissez avoir pris connaissance et avoir accepté pleinement et sans réserve les présentes conditions générales d'utilisation.</p>
                <p>MY THERMY attire votre attention sur l'importance de les lire attentivement.</p>

                <h3>Article 1. Objet</h3>
                <p>Les présentes conditions générales d’utilisation (« les Conditions d’Utilisation ») régissent l’utilisation du Site par tout utilisateur, personne physique, quel qu’il soit, âgée d’au moins 18 ans (« Vous »). Elles visent notamment à définir les conditions dans lesquelles vous pouvez accéder et naviguer sur le Site et créer un compte client.</p>

                <h3>Article 2. Site</h3>
                <p>Le Site est ouvert à tout internaute remplissant les conditions prévues à l’article « Objet » ci-dessus, sous réserve de disposer d’une connexion au réseau Internet.</p>

                <h3>Article 3. Compte Client</h3>
                <p>Pour accéder au Site, vous devez disposer d’un accès à Internet, dont le coût est à votre charge, et reconnaissez que :</p>
                <ul>
                    <li>La fiabilité des transmissions est aléatoire en raison, notamment, du caractère hétérogène des infrastructures et réseaux sur lesquelles elles circulent ;</li><br />
                    <li>Il vous appartient de prendre toute mesure que vous jugerez appropriée pour assurer la sécurité de votre terminal de connexion ;</li><br />
                    <li>Tout terminal de connexion connecté au Site est et reste sous votre entière responsabilité, la responsabilité de MY THERMY ne pouvant pas être recherchée pour tout dommage direct ou indirect qui pourrait survenir du fait de votre connexion au Site.</li><br />
                </ul>

                <h3>Article 4. Responsabilité de My Thermy</h3>
                <p>MY THERMY s’engage à faire ses meilleurs efforts afin d'assurer au mieux l'accès, la sécurité et le bon fonctionnement du Site.</p>
                <p>Cela étant, en naviguant sur le Site, vous reconnaissez connaître les caractéristiques et les limites du réseau Internet, en particulier ses performances techniques, les temps de réponse pour consulter, interroger ou transférer des données et les risques liés à la sécurité des communications.</p>
                <p>En dépit des soins et efforts qu'elle apporte à son Site, MY THERMY ne garantit pas que celui-ci sera exempt d’anomalies ou d’erreurs, que celles-ci pourront être corrigées, que le Site fonctionnera sans interruption ou panne, que des dysfonctionnements imputables à des logiciels de tiers, que ceux-ci soient ou non incorporés dans le Site ou fournis avec celui-ci, surviennent, ni encore que le Site soit compatible avec la nature ou la configuration de votre terminal de connexion.</p>
                <p>Vous reconnaissez être seul responsable de l'utilisation que vous faites du Site et que MY THERMY décline toute responsabilité en cas :</p>
                <ul>
                    <li>D’inconvénients ou dommages inhérents à l'utilisation du réseau Internet, notamment en cas de suspension ou d’interruption de l'accès au Site en raison d’un sinistre, problème sur la ligne, rupture de service, coupure d’électricité, intrusion extérieure, présence de virus informatiques ou de fichiers corrompus, maintenance ou mise à jour du Site, violation de données du Site ou accès non autorisé ;</li><br />
                    <li>de dommage de tout type, prévisible ou imprévisible (incluant la perte de données, de bénéfices, de chiffres d'affaires, d'activité, d’opportunité, d’économies, de profits, etc.) découlant de l’utilisation ou de l’impossibilité d’accéder totalement ou partiellement au Site ou de l'utilisation que vous pourriez en faire en violation des présentes Conditions d'Utilisation.</li><br />
                </ul>

                <h3>Article 5. Votre responsabilité</h3>
                <p>En accédant au Site, vous vous interdisez notamment de :</p>
                <ul>
                    <li>Collecter de quelque façon que ce soit des informations sur les autres visiteurs du Site, sans leur accord exprès, préalable et écrit ;</li><br />
                    <li>Perturber ou tenter de perturber le fonctionnement du Site, diffuser des virus ou toutes autres technologies susceptibles de nuire à MY THERMY et aux autres visiteurs du Site, créer une saturation, d'inonder le serveur, tenter de sonder, de scruter ou de tester la vulnérabilité d'un système ou d'un réseau ou encore enfreindre les mesures de sécurité ou d'authentification sans en avoir reçu l'accord exprès, préalable et écrit de MY THERMY;</li><br />
                    <li>Utiliser des robots, spiders ou scrapers ou tout autre procédé automatique pour accéder au Site, pour quelque raison que ce soit;</li><br />
                    <li>Transférer votre Compte client à un tiers sans l'accord exprès, préalable et écrit de GUESNEAU.</li><br />
                </ul>

                <h3>Article 6. Propriété Intellectuelle</h3>
                <p>Tous les droits de propriété intellectuelle portant tant sur la structure que sur le contenu du Site, et notamment les éléments graphiques, textuels ou visuels, les images, sons, vidéos, photographies, logos, marques, signes distinctifs, outils, logiciels, documents et données (ci-après désignés ensemble « les Eléments ») sont la propriété exclusive de MY THERMY ou, le cas échéant, de ses partenaires et ont, dans cette hypothèse, fait l’objet d’une autorisation expresse et préalable de reproduction et de représentation de la part du titulaire des droits.</p>
                <p>Ces Éléments sont mis à votre disposition à titre gracieux, pour la seule utilisation du Site et dans le cadre d'une utilisation normale de ses fonctionnalités.</p>
                <p>Lors de l’accès et de l'utilisation du Site, vous vous engagez à respecter les droits de propriété intellectuelle de MY THERMY et de ses partenaires, sous peine d’engager votre responsabilité personnelle et de vous exposer à des poursuites civiles et/ou pénales.</p>
                <p>Il vous est interdit de reproduire, représenter, publier, transmettre, modifier, utiliser, adapter, traduire, diffuser, céder, exploiter ou extraire, à des fins commerciales ou non, tout ou partie des Eléments, et ce de quelque manière que ce soit, sans l'accord exprès, préalable et écrit de MY THERMY.</p>
                <p>Par dérogation au paragraphe précédent, vous êtes autorisé à télécharger une copie de certaines parties du Site sur un ordinateur personnel et/ou à procéder à une impression, exclusivement pour votre utilisation personnelle et non commerciale et sous réserve de n'apporter aucune modification aux Éléments copiés.</p>

                <h3>Article 7. Liens Hypertextes</h3>
                <ol>
                    <li><strong>Liens hypertextes depuis le site</strong></li><br />
                    <p>Le Site peut contenir des liens hypertextes redirigeant vers des sites Internet exploités par des tiers. Ces liens sont fournis à simple titre d'information. MY THERMY n'exerce aucun contrôle sur ces sites Internet et décline toute responsabilité quant à l'accès, le contenu ou l'utilisation de ces sites, ainsi qu'aux dommages pouvant résulter de la consultation des informations qui y figurent, la décision d'activer ces liens hypertextes relevant de votre responsabilité exclusive.</p>
                    <li><strong>Liens hypertextes vers le site</strong></li><br />
                    <p>Aucun lien hypertexte ne peut être créé vers le Site sans l'accord exprès, préalable et écrit de MY THERMY.</p>
                    <p>Si vous entendez créer un lien hypertexte redirigeant vers le Site, et ce quel que soit le support, vous devez préalablement prendre contact avec MY THERMY afin d'obtenir son accord exprès, préalable et écrit en lui adressant un courrier électronique.</p>
                </ol>

                <h3>Article 8. Loi Applicable - Compétence Juridictionnelle</h3>
                <p>Les présentes Conditions d'utilisation sont soumises au droit français.</p>
                <p>Vous êtes informé qu'en cas de contestation, vous pouvez recourir à une médiation de la consommation dans les conditions prévues dans nos conditions générales.</p>
                <p>En cas de litige auquel aurait pu donner lieu l’application des présentes Conditions d'Utilisation et qui ne serait pas amiablement résolu avec MY THERMY, les tribunaux français sont exclusivement compétents en application des règles éditées par le Code de procédure civile français.</p>

                <h3>Article 9. Violation des conditions d'utilisation</h3>
                <p>En cas de constatation par MY THERMY de la violation de votre part de l'une quelconque des stipulations des présentes Conditions d'Utilisation, des dispositions légales en vigueur ou des droits des tiers, et après notification écrite de vous mettre en conformité demeurée infructueuse pendant plus de huit jours, sauf cause grave justifiant l'absence de préavis ou l'application d'un préavis plus court, MY THERMY se réserve le droit de restreindre, suspendre ou interdire, de manière temporaire ou définitive, votre accès au Site et à votre Compte client.</p>

                <h3>Article 10. Modification du site et des CGU</h3>
                <p>MY THERMY se réserve le droit, à tout moment et sans préavis, de modifier les règles de fonctionnement du Site, de modifier ou supprimer tout ou partie de son contenu ainsi que d'en interrompre, temporairement ou définitivement, l'accessibilité, notamment afin de procéder à toute opération de correction, de maintenance ou de mise à jour.</p>
                <p>MY THERMY se réserve le droit, à tout moment et sans préavis, de modifier le contenu des présentes Conditions d’Utilisation, les modifications ainsi apportées étant applicables dès leur mise en ligne sur le Site. En conséquence, vous êtes invité à consulter régulièrement les présentes Conditions d’Utilisation..</p>

                <h3>Article 11. Droit de cession</h3>
                <p>MY THERMY se réserve le droit de céder à des tiers, en tout ou partie, les droits et obligations issus des présentes Conditions d’Utilisation, à condition de les céder dans les mêmes termes ou, à tout le moins, dans des termes qui ne soient pas moins avantageux pour vous.</p>

                <h3>Article 12. Dispositions Diverses</h3>
                <p>Si l'une quelconque des stipulations des présentes Conditions d’Utilisation était déclarée nulle ou inapplicable en application d'une loi, d'un règlement ou à la suite d'une décision de justice devenue définitive, elle serait réputée non écrite et les autres stipulations resteraient en vigueur.</p>
                <p>Le fait que MY THERMY ne se prévale pas, à un moment ou à un autre, d’une des stipulations des Conditions d’Utilisation ne pourra être interprété comme valant renonciation à s’en prévaloir.</p>
            </Grid>
        </Grid>
    )
}

export default Cgu