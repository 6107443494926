import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import './App.css'
import Home from './pages/Home'
import { StyledEngineProvider } from '@mui/material/styles'
import Contact from './pages/Contact/Contact'
import MobileMenu from './pages/components/MobileMenu'
import TopBar from './pages/components/TopBar'
import Footer from './pages/Home/Footer'
import Realisations from './pages/Realisations/Realisations'
import Cgu from './pages/legal/Cgu'
import Cgv from './pages/legal/Cgv'

const App = () => {
    const documentHeight = () => {
        const doc = document.documentElement
        doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
    }

    useEffect(() => {
        window.addEventListener('resize', documentHeight)
        documentHeight()
    }, [])

    const getMenu = () => {
        return window.innerWidth <= 780 ? (
            <MobileMenu />
        ): (
            <TopBar />
        )
    }

    return (
        <StyledEngineProvider injectFirst>
            <nav>
                {getMenu()}
            </nav>
            <Routes>
                <Route exact path='/' element={<Home />} />
                <Route exact path='/realisations' element={<Realisations />} />
                <Route exact path='/contact' element={<Contact />} />
                <Route exact path='/cgu' element={<Cgu />} />
                <Route exact path='/cgv' element={<Cgv />} />
            </Routes>
            <Footer sx={{ padding: '5vh' }} />
        </StyledEngineProvider>
    )
}

export default App
