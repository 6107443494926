import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Carousel from 'react-spring-3d-carousel'

const Carroussel = ({
    cards = [],
    offset,
    showArrows,
    width,
    height,
    margin,
    goToSlide = 0,
    setGoToSlide = () => {},
}) => {
    const table = cards.map((element, index) => ({ ...element, onClick: () => setGoToSlide(index) }))

    const [offsetRadius, setOffsetRadius] = useState(2)
    const [showArrowsState, setShowArrowsState] = useState(false)
    const [cardsState] = useState(table)

    useEffect(() => {
        setOffsetRadius(offset)
        setShowArrowsState(showArrows)
    }, [offset, showArrows])

    return (
        <div
            style={{ width, height, margin }}
        >
            <Carousel
                slides={cardsState}
                goToSlide={goToSlide}
                offsetRadius={offsetRadius}
                showNavigation={showArrowsState}
            />
        </div>
    )
}

Carroussel.propTypes = {
    cards: PropTypes.array,
    offset: PropTypes.number,
    showArrows: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
    margin: PropTypes.string,
    goToSlide: PropTypes.number,
    setGoToSlide: PropTypes.func,
}

export default Carroussel