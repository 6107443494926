import React from 'react'
import { Grid } from '@mui/material'

const Cgv = () => {
    return (
        <Grid container className='cgu'>
            <Grid item xs={12}>
                <h2>Conditions générales d’exécution de marchés privés de travaux à destination de clients</h2>

                <h3>1. Objet et champ d’application</h3>
                <p>Toute commande de travaux implique l’acceptation pleine et entière par le client des présentes conditions générales d’exécution et de règlement. Le devis et ses avenants constituent les conditions particulières des travaux à réaliser. Le contrat est soumis au droit français.</p>

                <h3>2. Durée de validité de l’offre</h3>
                <p>L’offre de l’entreprise a une validité de 1 mois à compter de sa date de remise au client. Si avant l’acceptation de l’offre, le client y apporte des modifications, l’entreprise se réserve le droit de les refuser ou de proposer une nouvelle offre.</p>

                <h3>3. Autorisations et accès</h3>
                <p>Le marché est conclu sous la condition suspensive d’obtention dans un délai de 3 mois, des autorisations administratives et/ou de voisinage nécessaires à l’exécution du marché. Le client se charge de l’obtention des autorisations liées au marché. Le client s’engage aussi à garantir à l’entreprise des conditions satisfaisantes d’accès au chantier et aux ouvrages.</p>

                <h3>4. Conditions d’exécution des travaux</h3>
                <p>Les travaux sont réalisés conformément à la réglementation et aux règles de l’art en vigueur au jour de l’offre. L’entreprise se réserve le droit de refuser l’utilisation des matériaux ou produits fournis par le client. Lorsque le support révèle des sujétions imprévues, non décelables par l’entreprise, sauf au moment des travaux, un avenant devra être conclu entre les parties pour fixer les travaux supplémentaires et leur coût.</p>

                <h3>5. Délai d’exécution</h3>
                <p>Les travaux seront réalisés dans le délai précisé au devis. A défaut, les travaux seront exécutés dans un délai maximum d'un an après la signature du contrat. Le délai d’exécution est prolongé, le cas échéant, à raison des avenants au marché ou de la durée des retards dus au client. Le délai d’exécution est également prolongé en cas de force majeure, d’intempéries, de grève générale de la profession, à l’exception des jours de grève propres à l’entreprise en particulier.</p>

                <h3>6. Modifications du marché – Avenants</h3>
                <p>Toutes les modifications apportées au marché feront l’objet d’avenants chiffrés conclus entre l’entreprise et le client.</p>

                <h3>7. Prix et règlements</h3>
                <p>Le prix du marché est fixé par le devis, modifié le cas échéant par avenants. Dans le cas de modification des charges imposées par voie législative ou réglementaire, le prix sera ajusté des dépenses ou économies en résultant. Les parties conviennent qu’en cas de requalification fiscale sur le taux de TVA applicable aux travaux, le client s’engage à assumer la totalité de la régularisation (principal, pénalités et intérêts) et à rembourser l’entreprise rectifiée fiscalement des sommes versées par elle à ce titre.</p>

                <h3>8. Recours à un prêt</h3>
                <p>Lorsque le client recourt à un prêt pour financer tout ou partie des travaux, il doit en informer l’entreprise et le marché est alors conclu sous les conditions prévues par le code de la consommation. Une information spécifique est complétée et annexée au contrat.</p>

                <h3>9. Conditions de règlement</h3>
                <p>Le règlement des factures se fait à réception de celles-ci Sauf conventions différentes figurant au présent document, le règlement des travaux sera effectué de la façon la suivante : - Pour une durée des travaux n’excédant pas 15 jours, il sera versé un acompte d’un tiers à la commande et un tiers au cours des travaux, le solde étant réglé après exécution, à la présentation de la facture, - Pour une durée des travaux supérieure à 15 jours, après versement d’un acompte d’un tiers du marché à la commande, les règlements seront effectués au fur et à mesure de l’état d’avancement des travaux, dans un délai de 7 jours à compter de la présentation des situations par l’entreprise au client. Le solde devra être réglé en totalité à l’achèvement des travaux sur présentation d’un mémoire définitif.</p>

                <h3>10. Assurance de responsabilité professionnelle (obligatoire)</h3>
                <p>L’entreprise a souscrit une assurance de responsabilité professionnelle auprès de BPCE, le numéro de contrat 144198602 R001 avec une zone de couverture étendue à toute la France.</p>

                <h3>11. Réception des travaux</h3>
                <p>La réception des travaux, par laquelle le client déclare accepter l’ouvrage avec ou sans réserve, se fait en présence de l’entrepreneur et du client. Elle donne lieu à un procès-verbal signé des deux parties, à raison d’un exemplaire pour l’entreprise et d’un exemplaire pour le client.</p>

                <h3>12. Résiliation du contrat</h3>
                <p>En cas d’inexécution de ses obligations par l’une des parties, le contrat pourra être résilié par le créancier de l’obligation inexécutée, (…) jours après mise en demeure par lettre recommandée avec accusé de réception restée sans effet, sans préjudice des dommages-intérêts qui pourraient être réclamés à la partie défaillante.</p>

                <h3>13. Délai de paiement</h3>
                <p>Les factures de l’entreprise sont payables comptant, sans retenue de garantie et sans escompte. Tout retard de paiement entraînera l’application, sans mise en demeure préalable, d’intérêts de retard au taux légal majoré de 7 points, calculés à compter de la date de facturation. Si le client est un professionnel : Tout retard de paiement entraîne de plein droit, outre les pénalités de retard, une obligation pour le débiteur de payer une indemnité forfaitaire de 40 euros pour frais de recouvrement. Une indemnité complémentaire pourra être réclamée, sur justificatif, lorsque les frais de recouvrement exposés sont supérieurs au montant de l’indemnité forfaitaire.</p>

                <h3>14. Délai de rétractation (en cas de contrat conclus hors établissement)</h3>
                <p>Le client bénéficie, dans les cas prévus par la loi, d’un délai de rétractation de 14 jours à compter de la date de signature du présent devis. Dans les cas précités, pour faciliter l’exercice de ce droit, un formulaire de rétractation est joint au présent devis.</p>

                <h3>15. Médiation de la consommation</h3>
                <p>En cas de litige non résolu par une solution amiable et ayant fait l’objet d’une réclamation écrite adressée par le client consommateur à l’entreprise, vous pouvez soumettre le différend au médiateur de la consommation dont les coordonnées sont les suivantes : ATLANTIQUE MEDIATION CONSO Maison de l’avocat 5 mail du front populaire 44200 NANTES www.consommation.atlantique-mediation.org consommation@atlantique-mediation.org</p>

                <h3>16. Autorisation de photographies</h3>
                <p>Le client autorise l’entreprise à prendre des photographies lors de la réalisation du chantier et à en faire usage, notamment pour promouvoir son image et son savoir-faire. Ces photographies pourront être utilisées pour ses documents commerciaux, son site internet, ses plaquettes commerciales, et lors de réponses à des appels d’offres publics ou privés.</p>

                <h3>17. Réserve de propriété</h3>
                <p>Le transfert de propriété des matériaux et équipements est suspendu jusqu’à complet paiement du marché.</p>

            </Grid>
        </Grid>
    )
}

export default Cgv