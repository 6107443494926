const generateRandom = () => Math.floor(Math.random() * (4 - 1)) + 1

const formatPictures = (pictures) => pictures.map(p => ({
    ...p,
    width: generateRandom(),
    height: generateRandom(),
    sizes: ['(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw'],
}))

const bathroomPictures = formatPictures([{
    src: '/assets/salle_bain/1.webp',
}, {
    src: '/assets/salle_bain/2.webp',
}, {
    src: '/assets/salle_bain/3.webp',
}, {
    src: '/assets/salle_bain/4.webp',
}, {
    src: '/assets/salle_bain/5.webp',
}, {
    src: '/assets/salle_bain/6.webp',
}, {
    src: '/assets/salle_bain/7.webp',
}, {
    src: '/assets/salle_bain/8.webp',
}, {
    src: '/assets/salle_bain/9.webp',
}, {
    src: '/assets/salle_bain/10.webp',
}, {
    src: '/assets/salle_bain/11.webp',
}, {
    src: '/assets/salle_bain/12.webp',
}, {
    src: '/assets/salle_bain/13.webp',
}, {
    src: '/assets/salle_bain/14.webp',
}, {
    src: '/assets/salle_bain/15.webp',
}, {
    src: '/assets/salle_bain/16.webp',
}, {
    src: '/assets/salle_bain/17.webp',
}, {
    src: '/assets/salle_bain/18.webp',
}, {
    src: '/assets/salle_bain/19.webp',
}, {
    src: '/assets/salle_bain/20.webp',
}, {
    src: '/assets/salle_bain/21-1600.webp',
    srcSet: [
        '/assets/salle_bain/21-500.webp 500w',
        '/assets/salle_bain/21-800.webp 800w',
        '/assets/salle_bain/21-1024.webp 1024w',
        '/assets/salle_bain/21-1600.webp 1600w',
    ],
}, {
    src: '/assets/salle_bain/22-1600.webp',
    srcSet: [
        '/assets/salle_bain/22-500.webp 500w',
        '/assets/salle_bain/22-800.webp 800w',
        '/assets/salle_bain/22-1024.webp 1024w',
        '/assets/salle_bain/22-1600.webp 1600w',
    ],
}, {
    src: '/assets/salle_bain/23-1600.webp',
    srcSet: [
        '/assets/salle_bain/23-500.webp 500w',
        '/assets/salle_bain/23-800.webp 800w',
        '/assets/salle_bain/23-1024.webp 1024w',
        '/assets/salle_bain/23-1600.webp 1600w',
    ],
}, {
    src: '/assets/salle_bain/24-1600.webp',
    srcSet: [
        '/assets/salle_bain/24-500.webp 500w',
        '/assets/salle_bain/24-800.webp 800w',
        '/assets/salle_bain/24-1024.webp 1024w',
        '/assets/salle_bain/24-1600.webp 1600w',
    ],
}, {
    src: '/assets/salle_bain/25-1600.webp',
    srcSet: [
        '/assets/salle_bain/25-500.webp 500w',
        '/assets/salle_bain/25-800.webp 800w',
        '/assets/salle_bain/25-1024.webp 1024w',
        '/assets/salle_bain/25-1600.webp 1600w',
    ],
}, {
    src: '/assets/salle_bain/26-1600.webp',
    srcSet: [
        '/assets/salle_bain/26-500.webp 500w',
        '/assets/salle_bain/26-800.webp 800w',
        '/assets/salle_bain/26-1024.webp 1024w',
        '/assets/salle_bain/26-1600.webp 1600w',
    ],
}, {
    src: '/assets/salle_bain/27-1600.webp',
    srcSet: [
        '/assets/salle_bain/27-500.webp 500w',
        '/assets/salle_bain/27-800.webp 800w',
        '/assets/salle_bain/27-1024.webp 1024w',
        '/assets/salle_bain/27-1600.webp 1600w',
    ],
}, {
    src: '/assets/salle_bain/28-1600.webp',
    srcSet: [
        '/assets/salle_bain/28-500.webp 500w',
        '/assets/salle_bain/28-800.webp 800w',
        '/assets/salle_bain/28-1024.webp 1024w',
        '/assets/salle_bain/28-1600.webp 1600w',
    ],
}])

const otherPictures = formatPictures([{
    src: '/assets/autres/1-1600.webp',
    srcSet: [
        '/assets/autres/1-500.webp 500w',
        '/assets/autres/1-800.webp 800w',
        '/assets/autres/1-1024.webp 1024w',
        '/assets/autres/1-1600.webp 1600w',
    ],
}, {
    src: '/assets/autres/2.webp',
}, {
    src: '/assets/autres/3.webp',
}, {
    src: '/assets/autres/4.webp',
}, {
    src: '/assets/autres/5-1600.webp',
    srcSet: [
        '/assets/autres/5-500.webp 500w',
        '/assets/autres/5-800.webp 800w',
        '/assets/autres/5-1024.webp 1024w',
        '/assets/autres/5-1600.webp 1600w',
    ],
}, {
    src: '/assets/autres/6-1600.webp',
    srcSet: [
        '/assets/autres/6-500.webp 500w',
        '/assets/autres/6-800.webp 800w',
        '/assets/autres/6-1024.webp 1024w',
        '/assets/autres/6-1600.webp 1600w',
    ],
}, {
    src: '/assets/autres/7-1600.webp',
    srcSet: [
        '/assets/autres/7-500.webp 500w',
        '/assets/autres/7-800.webp 800w',
        '/assets/autres/7-1024.webp 1024w',
        '/assets/autres/7-1600.webp 1600w',
    ],
}, {
    src: '/assets/autres/8-1600.webp',
    srcSet: [
        '/assets/autres/8-500.webp 500w',
        '/assets/autres/8-800.webp 800w',
        '/assets/autres/8-1024.webp 1024w',
        '/assets/autres/8-1600.webp 1600w',
    ],
}, {
    src: '/assets/autres/9-1600.webp',
    srcSet: [
        '/assets/autres/9-500.webp 500w',
        '/assets/autres/9-800.webp 800w',
        '/assets/autres/9-1024.webp 1024w',
        '/assets/autres/9-1600.webp 1600w',
    ],
}, {
    src: '/assets/autres/10-1600.webp',
    srcSet: [
        '/assets/autres/10-500.webp 500w',
        '/assets/autres/10-800.webp 800w',
        '/assets/autres/10-1024.webp 1024w',
        '/assets/autres/10-1600.webp 1600w',
    ],
}])

const renovationPictures = formatPictures([{
    src: '/assets/reno/1.webp',
}, {
    src: '/assets/reno/2.webp',
}, {
    src: '/assets/reno/3.webp',
}, {
    src: '/assets/reno/4.webp',
}, {
    src: '/assets/reno/5.webp',
}, {
    src: '/assets/reno/6.webp',
}, {
    src: '/assets/reno/7.webp',
}, {
    src: '/assets/reno/8.webp',
}, {
    src: '/assets/reno/9.webp',
}, {
    src: '/assets/reno/10.webp',
}, {
    src: '/assets/reno/11.webp',
}, {
    src: '/assets/reno/12.webp',
}, {
    src: '/assets/reno/13.webp',
}, {
    src: '/assets/reno/14.webp',
}, {
    src: '/assets/reno/15.webp',
}, {
    src: '/assets/reno/16.webp',
}, {
    src: '/assets/reno/17.webp',
}, {
    src: '/assets/reno/18.webp',
}, {
    src: '/assets/reno/19.webp',
}, {
    src: '/assets/reno/20.webp',
}, {
    src: '/assets/reno/21.webp',
}, {
    src: '/assets/reno/22.webp',
}, {
    src: '/assets/reno/23.webp',
}, {
    src: '/assets/reno/24.webp',
}, {
    src: '/assets/reno/25.webp',
}, {
    src: '/assets/reno/26.webp',
}, {
    src: '/assets/reno/27.webp',
}, {
    src: '/assets/reno/28.webp',
}, {
    src: '/assets/reno/29.webp',
}, {
    src: '/assets/reno/30.webp',
}, {
    src: '/assets/reno/31-1600.webp',
    srcSet: [
        '/assets/reno/31-500.webp 500w',
        '/assets/reno/31-800.webp 800w',
        '/assets/reno/31-1024.webp 1024w',
        '/assets/reno/31-1600.webp 1600w',
    ],
}, {
    src: '/assets/reno/32-1600.webp',
    srcSet: [
        '/assets/reno/32-500.webp 500w',
        '/assets/reno/32-800.webp 800w',
        '/assets/reno/32-1024.webp 1024w',
        '/assets/reno/32-1600.webp 1600w',
    ],
}, {
    src: '/assets/reno/33-1600.webp',
    srcSet: [
        '/assets/reno/33-500.webp 500w',
        '/assets/reno/33-800.webp 800w',
        '/assets/reno/33-1024.webp 1024w',
        '/assets/reno/33-1600.webp 1600w',
    ],
}, {
    src: '/assets/reno/34-1600.webp',
    srcSet: [
        '/assets/reno/34-500.webp 500w',
        '/assets/reno/34-800.webp 800w',
        '/assets/reno/34-1024.webp 1024w',
        '/assets/reno/34-1600.webp 1600w',
    ],
}, {
    src: '/assets/reno/35-1600.webp',
    srcSet: [
        '/assets/reno/35-500.webp 500w',
        '/assets/reno/35-800.webp 800w',
        '/assets/reno/35-1024.webp 1024w',
        '/assets/reno/35-1600.webp 1600w',
    ],
}, {
    src: '/assets/reno/36-1600.webp',
    srcSet: [
        '/assets/reno/36-500.webp 500w',
        '/assets/reno/36-800.webp 800w',
        '/assets/reno/36-1024.webp 1024w',
        '/assets/reno/36-1600.webp 1600w',
    ],
}, {
    src: '/assets/reno/37-1600.webp',
    srcSet: [
        '/assets/reno/37-500.webp 500w',
        '/assets/reno/37-800.webp 800w',
        '/assets/reno/37-1024.webp 1024w',
        '/assets/reno/37-1600.webp 1600w',
    ],
}, {
    src: '/assets/reno/38-1600.webp',
    srcSet: [
        '/assets/reno/38-500.webp 500w',
        '/assets/reno/38-800.webp 800w',
        '/assets/reno/38-1024.webp 1024w',
        '/assets/reno/38-1600.webp 1600w',
    ],
}, {
    src: '/assets/reno/39-1600.webp',
    srcSet: [
        '/assets/reno/39-500.webp 500w',
        '/assets/reno/39-800.webp 800w',
        '/assets/reno/39-1024.webp 1024w',
        '/assets/reno/39-1600.webp 1600w',
    ],
}, {
    src: '/assets/reno/40-1600.webp',
    srcSet: [
        '/assets/reno/40-500.webp 500w',
        '/assets/reno/40-800.webp 800w',
        '/assets/reno/40-1024.webp 1024w',
        '/assets/reno/40-1600.webp 1600w',
    ],
}, {
    src: '/assets/reno/41-1600.webp',
    srcSet: [
        '/assets/reno/41-500.webp 500w',
        '/assets/reno/41-800.webp 800w',
        '/assets/reno/41-1024.webp 1024w',
        '/assets/reno/41-1600.webp 1600w',
    ],
}])

const woodPictures = formatPictures([{
    src: '/assets/bois/1.webp',
}, {
    src: '/assets/bois/2.webp',
}, {
    src: '/assets/bois/3.webp',
}, {
    src: '/assets/bois/4.webp',
}, {
    src: '/assets/bois/5.webp',
}, {
    src: '/assets/bois/6.webp',
}, {
    src: '/assets/bois/7.webp',
}, {
    src: '/assets/bois/8.webp',
}, {
    src: '/assets/bois/9.webp',
}, {
    src: '/assets/bois/10.webp',
}, {
    src: '/assets/bois/11.webp',
}, {
    src: '/assets/bois/12-1600.webp',
    srcSet: [
        '/assets/bois/12-500.webp 500w',
        '/assets/bois/12-800.webp 800w',
        '/assets/bois/12-1024.webp 1024w',
        '/assets/bois/12-1600.webp 1600w',
    ],
}, {
    src: '/assets/bois/13-1600.webp',
    srcSet: [
        '/assets/bois/13-500.webp 500w',
        '/assets/bois/13-800.webp 800w',
        '/assets/bois/13-1024.webp 1024w',
        '/assets/bois/13-1600.webp 1600w',
    ],
}, {
    src: '/assets/bois/14-1600.webp',
    srcSet: [
        '/assets/bois/14-500.webp 500w',
        '/assets/bois/14-800.webp 800w',
        '/assets/bois/14-1024.webp 1024w',
        '/assets/bois/14-1600.webp 1600w',
    ],
}, {
    src: '/assets/bois/15-1600.webp',
    srcSet: [
        '/assets/bois/15-500.webp 500w',
        '/assets/bois/15-800.webp 800w',
        '/assets/bois/15-1024.webp 1024w',
        '/assets/bois/15-1600.webp 1600w',
    ],
}, {
    src: '/assets/bois/16-1600.webp',
    srcSet: [
        '/assets/bois/16-500.webp 500w',
        '/assets/bois/16-800.webp 800w',
        '/assets/bois/16-1024.webp 1024w',
        '/assets/bois/16-1600.webp 1600w',
    ],
}, {
    src: '/assets/bois/17-1600.webp',
    srcSet: [
        '/assets/bois/17-500.webp 500w',
        '/assets/bois/17-800.webp 800w',
        '/assets/bois/17-1024.webp 1024w',
        '/assets/bois/17-1600.webp 1600w',
    ],
}, {
    src: '/assets/bois/18-1600.webp',
    srcSet: [
        '/assets/bois/18-500.webp 500w',
        '/assets/bois/18-800.webp 800w',
        '/assets/bois/18-1024.webp 1024w',
        '/assets/bois/18-1600.webp 1600w',
    ],
}])

export { bathroomPictures, otherPictures, renovationPictures, woodPictures }