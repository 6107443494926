import { Button, TextField } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { mainGreen } from './theme'

export const PrimaryButton = styled(({ ...other }) => <Button variant='outlined' {...other} />)({
    border: '2px solid white',
    fontWeight: '400',
    fontSize: 18,
    padding: '1.2rem',
    color: 'white',
    borderRadius: '290486px',
    transition: 'all .2s ease-in-out',
    justifyContent: 'center',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    textTransform: 'none',
    lineHeight: 0,
    fontFamily: 'europa, sans-serif',
    boxShadow: '0 .125rem .25rem rgba(35,38,45,.09)',
    '&:hover': {
        color: 'white !important',
        border: `2px solid ${mainGreen} !important`,
        backgroundColor: mainGreen,
        boxShadow: '0 1rem 3rem rgba(35,38,45,.275)',
        transform: 'translate3d(0,-5px,0)',
    },
})

export const PrimaryTextField = styled(({ ...other }) => <TextField variant='outlined' fullWidth {...other} />)({
    '& label.Mui-focused': {
        color: mainGreen,
    },
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: mainGreen,
        },
        '&.Mui-focused fieldset': {
            borderColor: mainGreen,
        },
    },
})
