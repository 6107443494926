import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/logos/logo_black.png'
import { Grid } from '@mui/material'
import ContactsIcon from '@mui/icons-material/Contacts'
import TelegramIcon from '@mui/icons-material/Telegram'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'

const HOME = 'home_link'
const REALISATIONS = 'realisations_link'
const CONTACT = 'contact_link'

const MobileMenu = () => {
    const location = window.location.href.substring((window.location.href.lastIndexOf('/') + 1), window.location.href.length)
    const [selectedIcon, setSelectedIcon] = useState(location === 'realisations' ? REALISATIONS : location === 'contact' ? CONTACT : HOME)

    const navigate = useNavigate()

    const onClickLink = (menu, link) => {
        setSelectedIcon(menu)
        navigate(link)
        window.scrollTo(0, 0)
    }

    const setHomePage = () => {
        onClickLink(HOME, '/')
        window.scrollTo(0, 0)
    }

    const updateFooterStyle = () => {
        const mobileMenu = document.getElementById('mobileMenu')
        const footer = document.getElementById('footer')
        if (mobileMenu && mobileMenu.checked && footer) {
            if (footer) {
                footer.style.paddingBottom = '10vh'
            }
        } else if (footer) {
            footer.style.paddingBottom = '5vh'
        }
    }

    return (
        <Grid id='menu' container justifyContent='space-between' alignItems='center'>
            <Grid item xs={4} onClick={setHomePage}>
                <img id='app-logo' src={logo} alt='logo' />
            </Grid>
            <Grid item xs={1.5}>
                <div className='content'>
                    <div id='menuToggle'>
                        <input id='mobileMenu' aria-label='' type='checkbox' onClick={updateFooterStyle} />
                        <span />
                        <span />
                        <span />
                        <ul id='menuHidden2'>
                            <li>
                                <div onClick={() => onClickLink(HOME, '/')}>
                                    {selectedIcon === HOME ? (
                                        <>
                                            <HomeRoundedIcon
                                                sx={{
                                                    color: 'var(--main-black)',
                                                }}
                                            />
                                            <p>Accueil</p>
                                        </>
                                    ) : (
                                        <HomeRoundedIcon
                                            sx={{
                                                color: 'var(--main-grey)',
                                            }}
                                        />
                                    )}
                                </div>
                            </li>
                            <li>
                                <div onClick={() => onClickLink(REALISATIONS, '/realisations')}>
                                    {selectedIcon === REALISATIONS ? (
                                        <>
                                            <ContactsIcon
                                                sx={{
                                                    color: 'var(--main-black)',
                                                }}
                                            />
                                            <p>A propos</p>
                                        </>
                                    ) : (
                                        <ContactsIcon
                                            sx={{
                                                color: 'var(--main-grey)',
                                            }}
                                        />
                                    )}
                                </div>
                            </li>
                            <li>
                                <div onClick={() => onClickLink(CONTACT, '/contact')}>
                                    {selectedIcon === CONTACT ? (
                                        <>
                                            <TelegramIcon
                                                sx={{
                                                    color: 'var(--main-black)',
                                                }}
                                            />
                                            <p>Contact</p>
                                        </>
                                    ) : (
                                        <TelegramIcon
                                            sx={{
                                                color: 'var(--main-grey)',
                                            }}
                                        />
                                    )}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default MobileMenu