import React, { lazy, Suspense } from 'react'
import { Box, Grid, LinearProgress } from '@mui/material'
import Hello from './Home/Hello'


const About = lazy(() => import('./Home/About'))
const Banner = lazy(() => import('./Home/Banner'))
const CTABanner = lazy(() => import('./Home/CTABanner'))
const Expertise = lazy(() => import('./Home/Expertise'))
const Energy = lazy(() => import('./Home/Energy'))

const renderLoader = () => (
    <Box sx={{ width: '100%', padding: '2.5rem', backgroundColor: 'white' }}>
        <LinearProgress />
    </Box>
)

const Home = () => (
    <Grid container>
        <section id='accueil'>
            <Grid item xs={12} style={{ height: '100%' }}>
                <Hello />
            </Grid>
        </section>
        <Suspense fallback={renderLoader()}>
            <section id='section2'>
                <Grid item xs={12}>
                    <About />
                </Grid>
            </section>
        </Suspense>
        <Suspense fallback={renderLoader()}>
            <section id='section3' className='blackBackground'>
                <Grid item xs={12}>
                    <Banner />
                </Grid>
            </section>
        </Suspense>
        <Suspense fallback={renderLoader()}>
            <section id='section4'>
                <Grid item xs={12}>
                    <Expertise />
                </Grid>
            </section>
        </Suspense>
        <Suspense fallback={renderLoader()}>
            <section id='section3' className='blackBackground'>
                <CTABanner />
            </section>
        </Suspense>
        <Suspense fallback={renderLoader()}>
            <section id='section5'>
                <Energy />
            </section>
        </Suspense>
    </Grid>
)

export default Home