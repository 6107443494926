import React, { useState } from 'react'
import { Button, Grid } from '@mui/material'
import { mainGreen } from '../../components/styled/theme'
import { PrimaryTextField } from '../../components/styled/buttons'

const errorEmptyField = 'Veuillez remplir ce champ s\'il vous plait'

const Contact = () => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

    const [firstname, setFirstName] = useState('')
    const [lastname, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [submitted, setSubmitted] = useState(false)

    const sendFeedback = (templateId, variables) => {
        window.emailjs
            .send('service_msavlb8', templateId, variables)
            .then(_ => {
                successMessage()
                setFirstName('')
                setLastName('')
                setPhone('')
                setEmail('')
                setSubject('')
                setMessage('')
            })
            .catch(_ => {
                document.querySelector('.form-message').innerHTML = 'Une erreur s\'est produite lors de l\'envoi, veuillez réessayer s\'il vous plait.'
            })
    }

    const isEmail = () => email.match(regex)

    const successMessage = () => {
        let formMessage = document.getElementById('form-message')

        formMessage.innerHTML = 'Votre message a bien été envoyé, MYThermy vous remercie. Nous vous recontacterons dès que possible.'
        formMessage.style.visibility = 'visible'

        setTimeout(() => {
            formMessage.style.visibility = 'hidden'
            formMessage.style.color = '#fff'
        }, 5000)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        setTimeout(() => {
            setSubmitted(false)
        }, 2500)
        if (firstname && lastname && (email && isEmail()) && subject && phone && message) {
            sendFeedback('template_tevjk86', {
                firstname,
                lastname,
                phone,
                subject,
                reply_to: email,
                message,
            })
        }
    }

    const getEmailError = () => {
        if (submitted) {
            if (email && !email.match(regex)) {
                return 'Veuillez entre un email valide s\'il vous plait'
            }
            return errorEmptyField
        }
        return ''
    }

    return (
        <Grid container justifyContent='center' sx={{ marginTop: '5rem' }}>
            <Grid item xs={11} className='subtitle'>
                <h2><span style={{ color: mainGreen }}>MY</span> Contact</h2>
                <h3 id='contactSubtitle'>Une question ? Un conseil ? N’hésitez pas, nous sommes à l’écoute de nos clients</h3>
            </Grid>
            <Grid item lg={8} xs={10}>
                <Grid container justifyContent='center' className='contactForm'>
                    <Grid item xs={12} className='contactItem'>
                        <Grid container justifyContent='center' spacing={1}>
                            <Grid item md={3} xs={12}>
                                <PrimaryTextField
                                    error={submitted && !firstname}
                                    helperText={(submitted && !firstname) ? errorEmptyField : ''}
                                    id='firstname'
                                    value={firstname}
                                    label='Prénom'
                                    onChange={(v) => setFirstName(v.target.value)} variant='outlined'
                                />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <PrimaryTextField
                                    error={submitted && !lastname}
                                    helperText={(submitted && !lastname) ? errorEmptyField : ''}
                                    id='lastname'
                                    value={lastname}
                                    label='Nom'
                                    onChange={(v) => setLastName(v.target.value)} variant='outlined'
                                />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <PrimaryTextField
                                    error={submitted && !phone}
                                    helperText={(submitted && !phone) ? errorEmptyField : ''}
                                    id='phone'
                                    value={phone}
                                    label='Téléphone'
                                    onChange={(v) => setPhone(v.target.value)} variant='outlined'
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={9} className='contactItem'>
                        <PrimaryTextField
                            error={submitted && !subject}
                            helperText={(submitted && !subject) ? errorEmptyField : ''}
                            id='subject'
                            value={subject}
                            label='Objet'
                            onChange={(v) => setSubject(v.target.value)} variant='outlined'
                        />
                    </Grid>
                    <Grid item xs={12} md={9} className='contactItem'>
                        <PrimaryTextField
                            error={submitted && (!email || (email && !email.match(regex)))}
                            helperText={getEmailError()}
                            id='email'
                            value={email}
                            label='Email'
                            onChange={(v) => setEmail(v.target.value)} variant='outlined'
                        />
                    </Grid>
                    <Grid item xs={12} md={9} className='contactItem'>
                        <PrimaryTextField
                            id='message'
                            error={submitted && !message}
                            helperText={(submitted && !message) ? errorEmptyField : ''}
                            value={message}
                            label='Message'
                            multiline
                            rows={5}
                            onChange={(v) => setMessage(v.target.value)} variant='outlined'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent='center'>
                            <Grid item md={2} xs={6}>
                                <Button
                                    variant='contained'
                                    onClick={handleSubmit}
                                    fullWidth
                                    className='contactButton'
                                >
                                    ENVOYER
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={11} sm={9} md={7} id='form-message' className='form-message' />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Contact