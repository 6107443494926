import React, { useState } from 'react'
import { mainGreen } from '../../components/styled/theme'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import fd from '../../assets/fd/fd.webp'
import fd2 from '../../assets/fd/fd2.webp'
import fd3 from '../../assets/fd/fd3.webp'
import fd4 from '../../assets/fd/fd4.webp'
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded'
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'

const fadeImages = [{
    url: fd,
}, {
    url: fd2,
}, {
    url: fd3,
}, {
    url: fd4,
}]

const PrevArrow = (slickProps) => {
    const { onClick } = slickProps
    return (
        <KeyboardArrowLeftRoundedIcon
            sx={{ left: '5vh' }}
            onClick={onClick}
        />
    )
}

const NextArrow = (slickProps) => {
    const { onClick } = slickProps
    return (
        <KeyboardArrowRightRoundedIcon
            sx={{ right: '5vh' }}
            onClick={onClick}
        />
    )
}

const Hello = () => {
    const [autoplaySpeed, setAutoplaySpeed] = useState(2000)

    const settings = {
        dots: true,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed,
        speed: 1000,
        afterChange: (i) => {
            if (i === 0) {
                setAutoplaySpeed(5000)
            } else {
                setAutoplaySpeed(2500)
            }
        },
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    }

    return [
        <>
            <Slider className='slider' {...settings}>
                {fadeImages.map((img, i) => (
                    <div>
                        <img key={i} id={img.url} src={img.url} alt='slide' />
                    </div>
                ))}
            </Slider>
            <div className='containerTitle'>
                <div className='homePres'>
                    <h2><span style={{ color: mainGreen }}>MY</span> THERMY</h2>
                    <hr />
                    <h1>Entreprise de rénovation intérieure</h1>
                </div>
            </div>
        </>,

    ]
}

export default Hello