import React, { useEffect, useState } from 'react'
import logo from '../../assets/logos/logo_black.png'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'

const HOME = 'home_link'
const REALISATIONS = 'realisations_link'
const CONTACT = 'contact_link'
const linkArray = [HOME, REALISATIONS, CONTACT]
const menuClassName = 'selectedMenu'

const TopBar = () => {
    const location = window.location.href.substring((window.location.href.lastIndexOf('/') + 1), window.location.href.length)
    const [menuSelected, setMenuSelected] = useState(location === 'realisations' ? REALISATIONS : location === 'contact' ? CONTACT : HOME)

    const navigate = useNavigate()

    const onClickLink = (menu, link) => {
        setMenuSelected(menu)
        navigate(link)
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        linkArray.forEach(l => {
            const link = document.getElementById(l)
            if (l === menuSelected) {
                link.classList.add(menuClassName)
            } else {
                link.classList.remove(menuClassName)
            }
        })
    }, [menuSelected])

    const setHomePage = () => {
        onClickLink(HOME, '/')
        window.scrollTo(0, 0)
    }

    const getMenu = () => {
        switch (location) {
            case 'realisations':
                return REALISATIONS
            case 'contact':
                return CONTACT
            case '':
            default:
                return HOME
        }
    }

    useEffect(() => {
        const constantMenu = getMenu()
        if (menuSelected !== constantMenu) {
            window.scrollTo(0, 0)
            setMenuSelected(constantMenu)
        }
    }, [location])

    return (
        <Grid id='menu' container justifyContent={'space-between'}>
            <Grid item xs={2} onClick={setHomePage}>
                <img id='app-logo' src={logo} alt='logo' />
            </Grid>
            <Grid item xl={4} lg={5} md={7} style={{ textAlign: 'right' }}>
                <Grid container alignItems='center' style={{ height: '100%', textAlign: 'center' }}>
                    <Grid item md={4}>
                        <div onClick={() => onClickLink(HOME, '/')} className='primaryLink'>
                            <span id={HOME}>Accueil</span>
                        </div>
                    </Grid>
                    <Grid item md={4}>
                        <div onClick={() => onClickLink(REALISATIONS, '/realisations')} className='primaryLink'>
                            <span id={REALISATIONS}>Nos réalisations</span>
                        </div>
                    </Grid>
                    <Grid item md={4}>
                        <div onClick={() => onClickLink(CONTACT, '/contact')} id='contact-button' className='primaryLink'>
                            <span id={CONTACT}>Nous contacter</span>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TopBar