const mainGreen = '#8EBF21'
const mainBlack = '#0e0e0e'
const mainWhite = '#fafafa'
const mainGray = '#5C5953'
const lightGrey = '#e9ecef'

const lightGreen = 'rgba(142, 191, 33, 0.14)'

const mainRadius = '4px'

export { mainGreen, mainBlack, mainWhite, mainGray, lightGrey, lightGreen, mainRadius }