import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Card, Grid } from '@mui/material'
import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { mainGreen, mainRadius } from '../../components/styled/theme'
import wood1 from '../../assets/bois/1.webp'
import sallebain from '../../assets/fd/fd2.webp'
import other2 from '../../assets/autres/2.webp'
import reno1 from '../../assets/reno/1.webp'
import Carroussel from './Carroussel'
import { bathroomPictures, otherPictures, renovationPictures, woodPictures } from './Pictures'
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded'
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'
import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded'

const RealisationCard = ({ image, title }) => (
    <Card
        sx={{
            width: { xs: 300, sm: 350 },
            height: '100%',
            margin: 'auto',
            boxShadow: 'none',
            borderRadius: mainRadius,
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            position: 'relative',
        }}
    >
        <p className='realisationCardTitle'>{title}</p>
    </Card>

)

RealisationCard.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
}

const realisationsCards = [{
    key: 0,
    content: (
        <RealisationCard
            image={sallebain}
            title='Salle de bain'
        />
    ),
    description: 'Nous réalisons des salles de bain selon vos besoins et vos envies. Nos équipes peuvent créer / agrandir votre salle de bain ou effectuer quelques modifications.',
    pictures: bathroomPictures,
}, {
    key: 1,
    content: (
        <RealisationCard
            image={wood1}
            title='Menuiserie Bois'
        />
    ),
    description: 'Le bois est un matériau noble et esthétique que nous apprécions vous proposer pour vos intérieurs et vos projets d\'extérieur.',
    pictures: woodPictures,
}, {
    key: 2,
    content: (
        <RealisationCard
            image={reno1}
            title='Rénovation Complète'
        />
    ),
    description: 'Nous vous accompagnons dans la globalité de votre projet pour vous proposer un seul interlocuteur : de la gestion à la réalisation des travaux. Votre projet clé en main.',
    pictures: renovationPictures,
}, {
    key: 3,
    content: (
        <RealisationCard
            image={other2}
            title='Autres'
        />
    ),
    description: 'Notre équipe vous propose de multiples prestations pour répondre à vos demandes même ponctuelles : Changement de tableau électrique, dépannage, création d’un escalier...',
    pictures: otherPictures,
}]

const Realisations = () => {
    const [goToSlide, setGoToSlide] = useState(0)

    const [currentImage, setCurrentImage] = useState(0)
    const [viewerIsOpen, setViewerIsOpen] = useState(false)

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index)
        setViewerIsOpen(true)
    }, [])

    const closeLightbox = () => {
        setCurrentImage(0)
        setViewerIsOpen(false)
    }

    const maxKey = useMemo(() => realisationsCards.length - 1, [])

    const description = useMemo(() => realisationsCards.find(r => r.key === goToSlide)?.description, [goToSlide])
    const pictures = useMemo(() => realisationsCards.find(r => r.key === goToSlide)?.pictures, [goToSlide])

    return (
        <Grid container className='realisationSection' justifyContent='center' alignItems='center' sx={{ marginTop: '5rem' }}>
            <Grid item xs={11} className='subtitle'>
                <h2><span style={{ color: mainGreen }}>MY</span> Réalisations</h2>
                <h3 id='contactSubtitle'>Découvrez quelque-unes de nos réalisations en sélectionnant la pièce de votre choix !</h3>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' item xs={12}>
                <Grid item xs={1}>
                    <KeyboardArrowLeftRoundedIcon
                        sx={{ left: { xs: '0', sm: '5vh' } }}
                        onClick={() => setGoToSlide(goToSlide === 0 ? maxKey : goToSlide - 1)}
                    />
                </Grid>
                <Grid item lg={6} md={8} sm={9} xs={10}>
                    <Carroussel
                        cards={realisationsCards}
                        height='500px'
                        width='80%'
                        margin='0 auto'
                        offset={3}
                        goToSlide={goToSlide}
                        setGoToSlide={setGoToSlide}
                    />
                </Grid>
                <Grid item xs={1}>
                    <KeyboardArrowRightRoundedIcon
                        sx={{ right: { xs: '0', sm: '5vh' } }}
                        onClick={() => setGoToSlide(goToSlide === maxKey ? 0 : goToSlide + 1)}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent='center'
                item
                xs={12}
                sx={{
                    marginTop: '8rem',
                    backgroundColor: 'rgb(14 14 14 / 0.9)',
                    padding: '1.5rem',
                    borderRadius: mainRadius,
                }}
            >
                <Grid container item lg={7} xs={10} justifyContent='center'>
                    <Grid item xs={12}>
                        <FormatQuoteRoundedIcon
                            sx={{
                                color: 'var(--main-white) !important',
                                fontSize: '3.5rem !important',
                                width: 'auto !important',
                            }}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <p style={{
                            textAlign: 'center',
                            margin: 0,
                            fontSize: '18px',
                            color: '#fafafa',
                        }}
                        >
                            {description}
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <FormatQuoteRoundedIcon
                            sx={{
                                color: 'var(--main-white) !important',
                                fontSize: '3.5rem !important',
                                width: 'auto !important',
                                transform: 'rotateX(180deg)',
                                float: 'right',
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {pictures && (
                <Grid item xs={12}>
                    <Gallery photos={pictures} onClick={openLightbox} />
                    <ModalGateway>
                        {viewerIsOpen ? (
                            <Modal onClose={closeLightbox}>
                                <Carousel
                                    currentIndex={currentImage}
                                    views={pictures.map(x => ({
                                        ...x,
                                        srcset: x.srcSet,
                                        caption: x.title,
                                    }))}
                                />
                            </Modal>
                        ) : null}
                    </ModalGateway>
                </Grid>
            )}
        </Grid>
    )
}

export default Realisations